import React from "react";
import "./index.css";

import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Block from "../components/Block";
import BlockTitle from "../components/BlockTitle";
import HeroTitle from "../components/HeroTitle";
import BlockFull from "../components/BlockFull";
import SEO from "../components/SEO";
import Faq from "../components/Faq";
import SvgWidget from "../components/svg/SvgWidget";
import CtaBookMeeting from "../components/CtaBookMeeting";
import BlockSubtitle from "../components/BlockSubtitle";
import Tickmarks from "../components/Tickmarks";

const seo = {
  title: "Handpicked Cherries",
  description:
    "Miniverkkokauppa pintamateriaalien mallipalojen myyntiin. Parempi asiakaskokemus ja enemmän myyntiä.",
  url: "https://handpickedcherries.com",
  image: "https://handpickedcherries.com/images/handpickedcherries-og-meta.png"
};

const Index = () => (
  <Layout>
    <SEO config={seo} />
    <Hero>
      <div className="flex mb-12 xl:mb-24 flex-col-reverse md:flex-row">
        <div className="flex flex-col flex-1 justify-center">
          <div className="">
            <div className="text-center md:text-left">
              <HeroTitle>
                Parempi
                <br />
                asiakaskokemus.
                <br />
                <span className="text-red">Enemmän myyntiä.</span>
              </HeroTitle>
              <div className="mt-6 md:text-lg w-3/4 mx-auto md:mx-0">
                <p>
                  Pintamateriaalien valinta alkaa nykyisin netistä, mutta ennen
                  lopullisen ostopäätöksen tekemistä asiakas haluaa nähdä
                  valitsemansa mallit.
                </p>
                <p>Handpicked Cherriesin miniverkkokaupan avulla</p>
                <Tickmarks
                  items={[
                    "Tavoitat asiakkaat, joiden ostoikkuna on aukeamassa",
                    "Saat heidät käyttämään aikaa juuri sinun valikoimasi parissa",
                    "Parannat brändikokemusta ja lisäät myyntiä"
                  ]}
                />
              </div>
            </div>

            <div className="mt-12 text-center md:text-left lg:mb-12">
              <CtaBookMeeting />
            </div>
          </div>
        </div>

        <div className="md:flex items-start md:items-center lg:items-start mb-6 md:my-0 flex-1">
          <SvgWidget
            productImageUrl="https://hpc-images.imgix.net/295b3295-6d68-4073-8f26-cffd3a6b16b2/69799.jpg?w=900&h=600&fit=crop&crop=edges&q=30"
            colorCode="Kelta-vihreä"
            logoImageUrl="/images/references/tapettitehdas-logo.png"
            // productImageColor="#59666C"
            textColor="white"
            price={"3,00"}
            subtitle1="Appelsiini"
          />
        </div>
      </div>
    </Hero>

    <BlockFull bgColor="#4F665D">
      <BlockTitle marginBottom white id="common-problems" center>
        Käännä kotisivujesi vierailijat ostajiksi
      </BlockTitle>

      <div className="sm:flex justify-around mx-auto lg:w-3/4 text-white">
        <div className="flex-1 sm:mr-8 mb-6 sm:mb-0">
          <p className="font-bold">
            Palvelumme avulla se onnistuu. Kuluttajat, arkkitehdit ja muut
            suunnittelijat vierailevat sivustollasi löytääkseen vaihtoehtoja
            projektiinsa. Sivuilta löytyy yleensä aina tarvittava tuotetieto,
            mutta värit ja struktuurit eivät koskaan toistu ruudulla 100 %
            oikein.
          </p>
          <p>
            Handpicked Cherries mallipalapalvelu saa heidät kiinni tässä
            varhaisessa ja tärkeässä kohdassa ostopolkua.
          </p>
          <ul className="ml-4 list-disc list-outside font-bold">
            <li className="mb-2 sm:mb-4">
              Tavoitat asiakkaat, joiden ostoikkuna on aukeamassa. Saat
              asiakkaat käyttämään aikaa juuri sinun valikoimasi parissa.
            </li>
            <li className="mb-2 sm:mb-4">
              Autat asiakasta tekemään valintansa ja parannat tutkitusti
              brändikokemusta
            </li>
            <li className="mb-2 sm:mb-4">
              Lisäät omaa ja jälleenmyyjiesi myyntiä
            </li>
          </ul>
        </div>

        <div className="flex-1 sm:ml-8">
          <p>
            Etukoodeilla tehostat kampanjontia eri kanavissa. Me hoidamme
            puolestasi koodien luonnin, katalogin ylläpidon ja tulosten
            raportoinnin. Kerätyt asiakasliidit siirrämme valintasi mukaan omaan
            CRM-järjestelmääsi tai lämmitämme niitä markkinoinnin automaatiolla
            puolestasi.
          </p>
          <p>
            Haluatko kuulla lisää siitä, miten tehdä markkinoinnistasi{" "}
            <strong>vaivatonta, mitattavaa ja tuloksellista?</strong>
          </p>
        </div>
      </div>

      <div className="text-center mx-auto mt-8">
        <CtaBookMeeting bgColor="white" outline />
      </div>
    </BlockFull>

    <BlockFull bgColor="white">
      <BlockTitle center marginBottom>
        Todistetusti toimiva palvelu
      </BlockTitle>
      <div className="sm:flex justify-center items-start">
        <div className="text-center mx-5 sm:flex-1 mb-6 sm:mb-0">
          <div className="font-bold text-2xl">+40 000</div>
          <div className="">mallipalatilausta</div>
        </div>
        <div className="text-center mx-5 sm:flex-1 mb-6 sm:mb-0">
          <div className="font-bold text-2xl">+150 000</div>
          <div className="">myytyä mallipalaa</div>
        </div>
        <div className="text-center mx-5 sm:flex-1 mb-6 sm:mb-0">
          <div className="font-bold text-2xl">+500 000</div>
          <div className="">käyntiä mallipalapalveluissa</div>
        </div>
        <div className="text-center mx-5 sm:flex-1 mb-6 sm:mb-0">
          <div className="font-bold text-2xl">90 %</div>
          <div className="">asiakkaista kertoo hyötyneensä mallipaloista</div>
        </div>
      </div>
    </BlockFull>

    <BlockFull>
      <div className="lg:flex max-w-lg md:max-w-none mx-auto justify-center">
        <div className="flex flex-1 flex-col max-w-md">
          <div className="flex lg:h-24 mt-12 lg:mt-0 mb-8 items-center justify-center">
            <img
              src="/images/references/tapettitehdas-logo.png"
              alt="Tapettitehdas Pihlgren & Ritola"
              className="w-24"
            />
          </div>
          <div className="text-center px-4">
            <p className="mb-4">
              Tapettitehdas Pihlgren & Ritolan näytepalvelu on toteutettu heidän
              oman verkkokauppansa päälle. Postitse toimitettavien mallipalojen
              tilauskustannukset hyvitetään asiakkaalle täysimääräisinä, kun hän
              myöhemmin tilaa valitsemansa tapetin.
            </p>
            <p>
              <a
                href="https://www.tapettitehdas.fi/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-bold underline"
              >
                Kokeile palvelua
              </a>
            </p>
          </div>
        </div>
      </div>
    </BlockFull>

    <BlockFull bgColor="#EFDFE4">
      <BlockTitle marginBottom id="common-problems" center>
        7 syytä miksi maksullinen mallipalvelu toimii paremmin kuin ilmaiset
        näytteet
      </BlockTitle>

      <div className="sm:flex justify-around mx-auto lg:w-3/4 ">
        <div className="flex-1 sm:mr-8 mb-6 sm:mb-0">
          <ol className="ml-4 list-decimal list-outside" start="1">
            <li className="mb-4">
              Täysin <strong>ilmaista ei arvosta kukaan.</strong>{" "}
              Maksullisuudella lisäät kaikista helpoiten tuotteen ja tuottamasi
              palvelun arvoa.
            </li>
            <li className="mb-4">
              Mallien lähettäminen postitse ei ole ilmaista. Asiakkaasi ymmärtää
              tämän, ja <strong>osaa laskea arvon myös omalle ajalleen.</strong>
            </li>
            <li className="mb-4">
              Maksullisten mallien tilausmäärää ei ole syytä rajoittaa.
              Mallipalakauppa ja mallien myyminen kertovat siitä, että{" "}
              <strong>ymmärrät asiakkaan valintatilannetta.</strong>
            </li>
            <li className="mb-4">
              Mallien lähettäminen askartelutarvikkeiksi ei harmita, kun saat
              niistä maksun. Parhaassa tapauksessa{" "}
              <strong>
                käännät mallien lähettämisen kassavirtapositiiviseksi
              </strong>{" "}
              palveluksi.
            </li>
          </ol>
        </div>

        <div className="flex-1 sm:ml-8">
          <ol className="ml-4 list-decimal list-outside" start="5">
            <li className="mb-4">
              Etukoodit toimivat tehokkaimmin, kun{" "}
              <strong>asiakkaasi näkee selvästi sen rahanarvoisen edun</strong>,
              jonka hän koodin käyttämällä saa.
            </li>
            <li className="mb-4">
              <strong>
                Maksu sitoo asiakkaan paremmin lopulliseen kauppaan.
              </strong>{" "}
              Hyvittämällä mallipalakulut varsinaisen ostoksen yhteydessä luot
              tehokkaan takaisinkytkennän markkinoinnista itse kauppaan ja
              lisäät kampanjoiden seurattavuutta.
            </li>
            <li className="mb-4">
              Arkkitehdit, asentajat ja jälleenmyyjät tilaavat mallit
              palvelussamme{" "}
              <strong>ilmaiseksi juuri silloin kun heille sopii</strong> – myös
              iltaisin ja viikonloppuisin. Kerätyt liidit siirrämme
              CRM-järjestelmääsi tai suoraan nimeämillesi myyjille.
            </li>
          </ol>
        </div>
      </div>

      <div className="text-center mx-auto mt-8">
        <CtaBookMeeting outline />
      </div>
    </BlockFull>

    <BlockFull bgColor="white">
      <BlockTitle marginBottom center>
        Valmis alusta helpottaa omaa työtäsi
      </BlockTitle>
      <div className="flex flex-col md:flex-row col-gap-8">
        <div className="flex-1">
          <div className="flex-1">
            <div className="text-center">
              <BlockTitle small>Markkinointi saa uutta virtaa</BlockTitle>
            </div>
            <div className="mt-6">
              <div className="text-center my-6">
                <BlockSubtitle gray>
                  Mainos, joka ei tyrkytä vaan palvelee
                </BlockSubtitle>
                <div className="mb-10 lg:mb-0 text-gray">
                  Asiakas ei poistu jälleenmyyjäsi tai kumppanin sivustolta
                  palvelun avatessaan. Tämä lisää mahdollisuuksiasi istuttaa
                  palvelu kumppanisivustoille. Oma brändisi näkyy kuitenkin aina
                  haluamallasi tavalla, ja kiitos hyvin toimivasta palvelusta
                  sataa teidän kummankin laariin.
                </div>
              </div>

              <div className="text-center my-6">
                <BlockSubtitle gray>
                  Toimitusvaihtoehtona myös nouto myymälästä
                </BlockSubtitle>
                <div className="mb-10 lg:mb-0 text-gray">
                  Tarjoamalla ilmaisena toimitustapana noudon myymälästä ajat
                  loppuasiakkaita omiin tai jälleenmyyjän myymälöihin. Samalla
                  teet myymäläverkostoasi asiakkaalle tutuksi.
                </div>
              </div>

              <div className="text-center my-6">
                <BlockSubtitle gray>Monipuoliset etukoodit</BlockSubtitle>
                <div className="mb-10 lg:mb-0 text-gray">
                  Käytä eri etukoodeja messuilla, somekampanjoissa tai vaikkapa
                  printissä. Kohdista etu yksittäiseen tuotteeseen,
                  tuotekategoriaan tai vain toimituskuluihin. Palvelumme avulla
                  toteutat mitä erilaisempia kampanjoita ja seuraat tarkasti
                  konversiota.
                </div>
              </div>

              <div className="text-center my-6">
                <BlockSubtitle gray>Ammattilaisille oma palvelu</BlockSubtitle>
                <div className="mb-10 lg:mb-0 text-gray">
                  Haluatko palvella tiettyjä sidosryhmiä erityisen hyvin?
                  Suunnittelijat, asentajat ja jälleenmyyjät voivat haluttaessa
                  rekisteröityä helposti palveluun ja tilata malleja ilmaiseksi
                  itselleen tai suoraan omille asiakkailleen.
                </div>
              </div>

              <div className="text-center my-6">
                <BlockSubtitle gray>
                  Tehoa markkinoinnin automaatiolla
                </BlockSubtitle>
                <div className="mb-10 lg:mb-0 text-gray">
                  Pohjimmiltaan mallien myynnissä on kyse liidien tuottamisesta.
                  Palvelumme on loistava tapa kasvattaa asiakasrekisteriä.
                  Olemme toteuttaneet asiakkaillemme erilaisia jälkihoitomaileja
                  mm. personoitujen muistutusten muodossa, joilla palvelu
                  saadaan tuntumaan yksilölliseltä.
                </div>
              </div>

              <div className="text-center my-6">
                <BlockSubtitle gray>Tuki monelle eri kielelle</BlockSubtitle>
                <div className="mb-10 lg:mb-0 text-gray">
                  Palvelumme on täysin kieliversioitu alusta loppuun. Olemme
                  toteuttaneet mallipalvelun jo englanniksi, ruotsiksi, norjaksi
                  ja saksaksi. Toteutamme lisää kieliversioita tarpeesi mukaan.
                  Toimita malleja mihin vain vientimaistasi tai tue paikallisia
                  kumppaneitasi tarjoamalle heille valmis konsepti myynnin
                  edistämiseen.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="text-center">
            <BlockTitle small>
              IT-osasto kiittää huolellisesta toteutuksesta
            </BlockTitle>
          </div>
          <div className="mt-6">
            <div className="text-center my-6">
              <BlockSubtitle gray>Helppo asentaa eri sivustoille</BlockSubtitle>
              <div className="mb-10 lg:mb-0 text-gray">
                Palvelu voidaan istuttaa helposti mille vain nettisivulle tai
                verkkokaupan päälle. Näet helposti, tuleeko tilaus omalta vai
                jälleenmyyjän sivustolta, ja voit palkita myyjiä tuloksista.
              </div>
            </div>
            <div className="text-center my-6">
              <BlockSubtitle gray>Ei huolta ylläpidosta</BlockSubtitle>
              <div className="mb-10 lg:mb-0 text-gray">
                Ulkoistetun palvelun koko idea on siinä, että sinun ei tarvitse
                huolehtia palvelimien tai järjestelmän toimivuudesta. Oli
                liikennettä tai tilauksia kuinka paljon vain, palvelumme toimii.{" "}
              </div>
            </div>
            <div className="text-center my-6">
              <BlockSubtitle gray>Rakennettu mobiili edellä</BlockSubtitle>
              <div className="mb-10 lg:mb-0 text-gray">
                Kuluttajat selaavat nettiä, kun heillä on siihen aikaa. Yli
                puolet tilauksista tulee mobiililaitteista. Palvelumme toimii
                sujuvasti eri laitteissa ja selaimissa, ja päivittyy ilman
                lisäkustannuksia käyttäjien laitekannan mukana.
              </div>
            </div>
            <div className="text-center my-6">
              <BlockSubtitle gray>Automatisoidut päivitykset</BlockSubtitle>
              <div className="mb-10 lg:mb-0 text-gray">
                Päivittyykö tuotekatalogisi usein? Tämä ei ole ongelma.
                Tarvittaessa automatisoimme päivityksen integraatioilla ja
                pidämme huolta siitä, että valikoimasi on mallipalvelussa aina
                ajan tasalla.
              </div>
            </div>
            <div className="text-center my-6">
              <BlockSubtitle gray>Kattavaa tietoturvaa</BlockSubtitle>
              <div className="mb-10 lg:mb-0 text-gray">
                Palvelumme toteutus nojaa tukevasti tunnettuihin
                palveluntarjoajiin kuten Amazon Web Services, Heroku, Stripe,
                Imgix ja Twilio. Vuokraamamme palvelimet sijaitsevat Euroopassa,
                ja yhteistyössämme on sovittu asianmukaisesti GDPR:ään
                liittyvistä käytännöistä.
              </div>
            </div>
            <div className="text-center my-6">
              <BlockSubtitle gray>CRM-integraatiot</BlockSubtitle>
              <div className="mb-10 lg:mb-0 text-gray">
                Asiakastiedot ovat yhteistyössämme yksin yrityksesi omaisuutta.
                Olemme toteuttaneet integraation HubSpotiin, jonka avulla
                liidejä voidaan rikastaa mallipalatilausten tiedoilla. Jos
                valmiit integraatiomme eivät riitä, yhteistyössä oman
                IT-osastonne tai verkkosivujenne kehittäjän kanssa saamme homman
                toimimaan.
              </div>
            </div>
          </div>
        </div>
      </div>
    </BlockFull>

    <Block>
      <BlockTitle marginBottom center>
        Mitä tarkalleen ottaen ehdotamme?
      </BlockTitle>

      <div className="md:flex md:w-3/4 md:m-auto text-gray">
        <div className="flex-1 text-center md:px-2 md:mb-0 mb-4">
          <div className="font-bold text-3xl md:text-5xl">1</div>
          <div className="font-bold text-lg uppercase mb-3">Esittely</div>
          <p>
            Sovitaan tapaaminen, jossa kuulet palvelusta ja sen tuottamista
            tuloksista tarkemmin.
          </p>
        </div>
        <div className="flex-1 text-center md:px-2 md:mb-0 mb-4">
          <div className="font-bold text-3xl md:text-5xl">2</div>
          <div className="font-bold text-lg uppercase mb-3">Tarjous</div>
          <p>
            Jos innostut, me teemme yrityksellesi räätälöidyn ehdotuksen
            toteutuksesta. Tämä ei maksa mitään eikä sido teitä vielä kokeiluun.
          </p>
        </div>
        <div className="flex-1 text-center md:px-2 md:mb-0 mb-4">
          <div className="font-bold text-3xl md:text-5xl">3</div>
          <div className="font-bold text-lg uppercase mb-3">Toteutus</div>
          <p>
            Jos olet tyytyväinen ehdotukseemme palvelun sisällöstä ja
            ulkonäöstä, toteutamme sen sovitusti ja autamme asentamaan palvelun
            sivuillenne.
          </p>
        </div>
      </div>

      <div className="text-center mt-12">
        <CtaBookMeeting />
      </div>

      <p className="max-w-lg mx-auto text-center mt-12">
        Jos palvelu ei vastaa odotuksiasi ja päätät olla ryhtymättä
        yhteistyöhön, olet keskustelumme myötä kuitenkin hyvin kartalla siitä,
        mihin suuntaan kuluttajapalvelut ovat tällä hetkellä toimialallasi
        kehittymässä.
      </p>
    </Block>

    <BlockFull bgColor="white">
      <BlockTitle center marginBottom>
        Usein kysytyt kysymykset
      </BlockTitle>

      <div className="max-w-3xl mx-auto">
        <Faq />
      </div>
    </BlockFull>
  </Layout>
);

export default Index;
